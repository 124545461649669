import { SET_USER } from "../actionTypes";

const initialState = {};

export default function(state = initialState, action:any) {
  switch (action.type) {
    case SET_USER: {
      return action.payload;
    }
    default:
      return state;
  }
}
