import React from 'react'
import { Link } from 'react-router-dom'
import CodeInput from 'components/CodeInput/CodeInput'
import { apiCLIENTVERIFYACCOUNT } from 'constants/Api'
import Button from 'components/Button/Button'

export default class ConfirmCode extends React.Component {
    state = {
        code: "",
        codeError: "",
        loading: false,
        nextError: ""
    }
    props: {
        onNext: (any?: any) => any,
        email: string
    }
    constructor(props: any) {
        super(props)
        this.props = props
    }
    validate = () => {
        const completeMessage = "Please complete this field"
        const { code } = this.state
        let codeError = (code.trim() === "") || (code.length < 5) ? completeMessage : null

        const numberOnlyRegex = new RegExp(/^\d+$/)

        if (codeError === null)
            codeError = (numberOnlyRegex.test(code)) ? null : "Please enter a valid number"

        this.setState({
            codeError
        })
        return !codeError

    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError:null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    submitNext() {
        const { onNext } = this.props

        if (onNext) {
            const { code } = this.state
            this.setState({ loading: true }, () => {
                apiCLIENTVERIFYACCOUNT(code).then((resp) => {
                    if (resp.success) {
                        this.setState({ loading: false })
                        onNext()
                    } else {
                        this.setState({ codeError: "You have entered an incorrect verification code", loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })
            })
        }
    }
    render() {
        const { code, codeError, loading, nextError} = this.state
        return (
            <div className="enter-email-container">
                <div className="header">Enter confirmation code</div>
                <div className="sub-header">We have sent you a verification code on your email {this.props.email}. Please enter the verification code below.</div>
                <div className="form">
                    <div className="form-header confirm-header">Confirmation Code</div>
                    <form noValidate autoComplete="off">
                        <CodeInput onChange={(code) => this.setState({ code, codeError: "" })} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{codeError}</span>
                        </div>
                        <div className="form-helper-btns">
                            <span className="center">Didn’t get an email? <span className="green"><Link to="">Send new code</Link></span></span>
                        </div>
                        <Button onClick={() => this.submitNext()} title="Next" loading={loading} disabled={code.length === 0} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{nextError}</span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
