import { IAuthResponseDTO,IGenericResponseDTO } from "./interfaces"
const { encrypt:mainEncrypt, decrypt:mainDecrypt } =  require("../../node_modules/caesar-encrypt")
const ENCRYPTION_CONSTANT=1
enum LSH_KEYS{
    TOKEN="token",
    EMAIL="email",
}

const encrypt=(value:string)=>mainEncrypt(value,ENCRYPTION_CONSTANT)
const decrypt=(value:string)=>mainDecrypt(value,ENCRYPTION_CONSTANT)

const _storeData = (key:string, value:any) => {
    try {
        try {
            value = encrypt(JSON.stringify(value));
        } catch (error) {
            
        }
        localStorage.setItem(encrypt(key), value);
        return {success: true};
    } catch (error) {
        return {success: false,...error};
    }
};
const _retrieveData = (key:string) => {
    try {
        const value = localStorage.getItem(encrypt(key));
        if (value !== null) {
            
            return JSON.parse(decrypt(value));
        } else {
            return null;
        }
    } catch (error) {
        return error;
    }
};
const _removeData = async (key:string) => {
    try {
        await localStorage.removeItem(encrypt(key));
        return Promise.resolve({ success: true });
    } catch (error) {
        return Promise.reject(error);
    }
};

export const STORAGE_ACTION = { _removeData, _retrieveData, _storeData }

export function lshSAVEAUTH(auth:IAuthResponseDTO):Promise<IGenericResponseDTO>{
    return Promise.resolve(_storeData(LSH_KEYS.TOKEN,auth.token?auth.token:""))
}

export function lshGETAUTH():Promise<IAuthResponseDTO>{
    return Promise.resolve(_retrieveData(LSH_KEYS.TOKEN) as IAuthResponseDTO)
}

export function lshREMOVEAUTH():Promise<IAuthResponseDTO>{
    return Promise.resolve(_removeData(LSH_KEYS.TOKEN))
}

export function lshSAVEEMAIL(email:string):Promise<IGenericResponseDTO>{
    return Promise.resolve(_storeData(LSH_KEYS.EMAIL,email))
}
