import React from 'react'
import TextField from '@material-ui/core/TextField';
import RadioInput from 'components/RadioInput/RadioInput';
import { IRadioInputType } from 'constants/interfaces'
import { apiCREATECOMPANY } from 'constants/Api';
import Button from 'components/Button/Button';

export default class CompanyDetails extends React.Component {
    state: {
        formlist: IRadioInputType,
        companyName: string,
        companyNameError: string,
        city: string,
        cityError: string,
        buyingOption: number
        buyingOptionError: string,
        loading: boolean,
        nextError:string
    } = {
            formlist: {
                name: "How-do-you-buy",
                list: [
                    {
                        id: 1,
                        name: "Adhoc"
                    },
                    {
                        id: 2,
                        name: "Contract"
                    }
                ]
            },
            companyName: "",
            companyNameError: "",
            city: "",
            cityError: "",
            buyingOption: 0,
            buyingOptionError: "",
            loading: false,
            nextError: ""
        }


    props: {
        onNext: (any?: any) => any
    }
    constructor(props: any) {
        super(props)
        this.props = props
    }
    validate = () => {
        const completeMessage = "Please complete this field"
        let companyNameError = (this.state.companyName.trim() === "") ? completeMessage : null,
            cityError = (this.state.city.trim() === "") ? completeMessage : null,
            buyingOptionError = (this.state.buyingOption === 0) ? completeMessage : null

        this.setState({
            companyNameError,
            cityError,
            buyingOptionError
        })
        return !companyNameError && !cityError && !buyingOptionError

    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError: null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    missingField() {
        const { companyName, city, buyingOption } = this.state
        return companyName.length > 0 && city.length > 0 && buyingOption !== 0
    }
    submitNext() {
        const { onNext } = this.props

        if (this.validate() && onNext) {
            const { companyName, city, buyingOption } = this.state
            this.setState({ loading: true }, () => {
                apiCREATECOMPANY(companyName, city, buyingOption).then((response) => {
                    if (response.success) {
                        this.setState({ loading: false })
                        onNext()
                    } else {
                        this.setState({ nextError: response.message, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })
            })

        }
    }
    render() {
        const { companyName, companyNameError, city, cityError, formlist, buyingOption, buyingOptionError, loading, nextError} = this.state
        return (
            <div className="personal-details-container">
                <div className="header">About Company</div>
                <div className="sub-header"> Let's get your company set up. Please provide us with your company details.</div>
                <div className="form">
                    <div className="form-header">Company Details</div>

                    <form noValidate autoComplete="off">
                        <TextField
                            name="companyName"
                            className="text-input"
                            label="Company Name"
                            variant="outlined"
                            helperText={companyNameError}
                            value={companyName}
                            onChange={(e) => this.handleText(e)}
                        /><br />
                        <TextField
                            name="city"
                            className="text-input"
                            label="City"
                            variant="outlined"
                            helperText={cityError}
                            value={city}
                            onChange={(e) => this.handleText(e)}
                        />
                        <div className="radio-title">How do you buy?</div>
                        <RadioInput
                            name="buyingOption"
                            formlist={formlist}
                            onOptionSelected={(variable) => { this.setState({ buyingOption: variable, buyingOptionError: "" }) }}
                            value={buyingOption}
                        />
                        <div className="MuiFormHelperText-root">
                            <span>{buyingOptionError}</span>
                        </div>
                        <Button onClick={() => this.submitNext()} title="Next" loading={loading} disabled={!this.missingField()} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{nextError}</span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}