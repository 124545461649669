import React from 'react'
import { FeaturedProduct } from 'constants/interfaces'
import { _GetImagePath } from 'constants/index'
import placeholder from 'assets/images/img_placeholder.svg'

type BiddingCardProps = {
    product: FeaturedProduct
}

export default class BiddingCard extends React.Component<BiddingCardProps>{
    state: {
        src: string
    } = {
            src: ""
        }
    componentDidMount() {
        const { product } = this.props
        this.setState({ src: _GetImagePath(product.name) })
    }
    onError() {
        this.setState({ src: `${placeholder}` })
    }
    render() {
        const { product } = this.props
        const { src } = this.state
        return (
            <div className="bidding-card-container">
                <div className="card-header">{product.name}</div>
                <div className=""><img className="cards-img" alt={product.name} onError={(e) => this.onError()} src={src} />
                </div>
                <div className="bottom">
                    <div className="card-link pointer">See Farmers</div>
                    <div className="card-btn pointer">Bid</div>
                </div>
            </div>
        )
    }
}