import React from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { apiCLIENTSIGNUP } from 'constants/Api'
import Button from 'components/Button/Button'

export default class PersonalDetailsSignup extends React.Component {
    state = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        terms: false,
        firstnameError: "",
        lastnameError: "",
        emailError: "",
        phoneError: "",
        termsError: "",
        nextError: "",
        loading: false
    }
    props: {
        onNext: (any?: any) => any
    }
    constructor(props: any) {
        super(props)
        this.props = props
    }
    validate = () => {
        const completeMessage = "Please complete this field"
        let firstnameError = (this.state.firstname.trim() === "") ? completeMessage : null,
            lastnameError = (this.state.lastname.trim() === "") ? completeMessage : null,
            emailError = (this.state.email.trim() === "") ? completeMessage : null,
            phoneError = (this.state.phone.trim() === "") ? completeMessage : null,
            termsError = this.state.terms === false ? completeMessage : null

        const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        const numberOnlyRegex = new RegExp(/^\d+$/)

        if (emailError === null)
            emailError = (emailRegex.test(this.state.email)) ? null : "Please enter a valid email"

        if (phoneError === null)
            phoneError = (numberOnlyRegex.test(this.state.phone)) ? null : "Please enter a valid number"

        this.setState({
            firstnameError,
            lastnameError,
            emailError,
            phoneError,
            termsError
        })
        return !firstnameError && !lastnameError && !emailError && !phoneError && !termsError

    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError: null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    missingField() {
        const { firstname, lastname,
            email,
            phone,
            terms } = this.state

        return firstname.length > 0 && lastname.length > 0 && email.length > 0 && phone.length > 0 && terms
    }
    submitNext() {
        const { onNext } = this.props

        if (onNext && this.validate()) {
            const { firstname, lastname, email, phone } = this.state
            this.setState({ loading: true }, () => {
                apiCLIENTSIGNUP(firstname, lastname, email, phone).then((response) => {
                    if (response.success) {
                        this.setState({ loading: false })
                        onNext(email)
                    } else {
                        this.setState({ nextError: response.message, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })
            })
        }
    }
    render() {
        const { firstname, firstnameError, lastname,
            email,
            phone,
            terms,
            lastnameError,
            emailError,
            phoneError,
            termsError,
            nextError,
            loading } = this.state
        return (
            <div className="personal-details-container">
                <div className="header">Sign up for a <span className="green">Khula!</span> Account</div>
                <div className="sub-header"> Let's get you all set up. Please provide us with your personal details.</div>
                <div className="form">
                    <div className="form-header">Personal Details</div>

                    <form noValidate autoComplete="off">
                        <TextField
                            name="firstname"
                            className="text-input half"
                            label="First name"
                            variant="outlined"
                            helperText={firstnameError}
                            value={firstname}
                            onChange={(e) => this.handleText(e)}
                        />
                        <TextField
                            name="lastname"
                            className="text-input half"
                            label="Last name"
                            variant="outlined"
                            helperText={lastnameError}
                            value={lastname}
                            onChange={(e) => this.handleText(e)}
                        /><br />
                        <TextField
                            name="email"
                            className="text-input"
                            label="Email address"
                            variant="outlined"
                            helperText={emailError}
                            value={email}
                            onChange={(e) => this.handleText(e)}
                        /><br />
                        <TextField
                            name="phone"
                            className="text-input"
                            label="Phone"
                            variant="outlined"
                            helperText={phoneError}
                            value={phone}
                            onChange={(e) => this.handleText(e)}
                        />
                        <div className="form-helper-btns">

                            <FormControlLabel
                                name="terms"
                                value={terms}
                                control={<Checkbox color="primary" />}
                                label="I accept Khula! Terms &amp; Conditions"
                                labelPlacement="end"
                                onChange={(e) => this.setState({ terms: !terms, termsError: null })}
                            />
                            <FormHelperText>{termsError}</FormHelperText>
                        </div>
                        <Button onClick={() => this.submitNext()} title="Next" loading={loading} disabled={!this.missingField()} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{nextError}</span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}