import React from 'react'
import dp from 'assets/images/logo.png'
import { connect } from 'react-redux';
import { IClientUserProfile } from 'constants/interfaces';

type ClientProfileProps={
    user:IClientUserProfile
}
class ClientProfile extends React.Component<ClientProfileProps> {
    state:{
        src:any
    }={
        src:this.props.user.picture
    }
    render() {
        const {user} = this.props
        return (
        <div className="client-profile-container">
            <div className="client-profile-info row">
                <div className="display-pic-container">
                    <img className="display-pic" src={this.state.src} onError={()=>this.setState({src:dp})} alt="display-pic"/>
                </div>
                <div className="display-txt">
                    <div className="display-txt-name">{user.name} {user.surname}</div>
                    {user.company && <div className="display-txt-body">{user.company.name}</div>}
                    {user.location && <div className="display-txt-title">Delivery Address: <span className="display-txt-body">{user.location}</span></div>}
                    <hr/>
                </div>
            </div>
            <div className="client-profile-stats row">
                <div className="completed-trades center-text">
                    <div className="val green">300</div>
                    <div className="txt">Completed trades</div>
                </div>
            </div>
         </div>
        )
    }
}

function mapStateToProps(state: any) {
    return ({
      user: state.user,
    });
  }
export default connect(mapStateToProps)(ClientProfile)
