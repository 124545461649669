import React from 'react'
import TextField from '@material-ui/core/TextField';
import { apiUPDATECLIENTATTRIBUTE } from 'constants/Api';
import Button from 'components/Button/Button';
import { RouteScreenProps } from 'constants/interfaces';

export default class CreatePassword extends React.Component<RouteScreenProps> {
    state = {
        password: "",
        confirmPassword: "",
        passwordError: "",
        confirmPasswordError: "",
        nextError: "",
        loading: false
    }
    validate = () => {
        const completeMessage = "Please complete this field"
        let passwordError = (this.state.password.trim() === "") ? completeMessage : null,
            confirmPasswordError = (this.state.confirmPassword.trim() === "") ? completeMessage : null

        if (confirmPasswordError === null)
            confirmPasswordError = (this.state.confirmPassword.trim() !== this.state.password.trim()) ? "The passwords you entered do not match" : null

        this.setState({
            passwordError,
            confirmPasswordError
        })
        return !passwordError && !confirmPasswordError

    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError: null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    missingField() {
        const { password, confirmPassword } = this.state

        return password.length > 0 && confirmPassword.length > 0
    }
    submitNext() {
        if (this.validate()) {
            const { password } = this.state
            this.setState({ loading: true }, () => {
                apiUPDATECLIENTATTRIBUTE({ password: password }).then((response) => {
                    if (response.success) {
                        this.props.history.push("/")
                        this.setState({ loading: false })
                    } else {
                        this.setState({ nextError: response.message, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })
            })
        }
    }

    render() {
        const { password, confirmPassword, passwordError, confirmPasswordError, loading, nextError } = this.state

        return (
            <div className="confirm-container">
                <div className="header">Create your password</div>
                <div className="sub-header"> Please set password for your account</div>
                <div className="form">
                    <div className="form-header">Password</div>

                    <form noValidate autoComplete="off">
                        <TextField
                            name="password"
                            className="text-input"
                            label="Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            helperText={passwordError}
                            onChange={(e) => this.handleText(e)}
                        />
                        <TextField
                            name="confirmPassword"
                            className="text-input"
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            value={confirmPassword}
                            helperText={confirmPasswordError}
                            onChange={(e) => this.handleText(e)}
                        />
                        <Button onClick={() => this.submitNext()} title="Next" loading={loading} disabled={!this.missingField()} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{nextError}</span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}