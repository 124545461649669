import React from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from 'components/Button/Button'
import { apiCLIENTFORGOTPASSWORD } from 'constants/Api'

export default class EnterEmail extends React.Component {
    state = {
        email: "",
        emailError: "",
        loading: false,
        nextError:""
    }
    props: {
        onNext: (any?: any) => any
    }
    constructor(props: any) {
        super(props)
        this.props = props
    }
    validate = () => {
        const completeMessage = "Please complete this field"
        let emailError = (this.state.email.trim() === "") ? completeMessage : null

        const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

            if(emailError === null)
                emailError = (emailRegex.test(this.state.email)) ? null : "Please enter a valid email"

        this.setState({
            emailError
        })
        return !emailError

    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError: null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    submitNext() {
        const { onNext } = this.props
        if (this.validate() && onNext) {
            const { email } = this.state
            this.setState({ loading: true }, () => {
                apiCLIENTFORGOTPASSWORD(email).then((response) => {
                    if (response.success) {
                        this.setState({ loading: false })
                        onNext(this.state.email)
                    }else{
                        this.setState({ nextError: response.message, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })
            })
                    
        }
    }
    render() {
        const { email, emailError, loading, nextError} = this.state
        return (
            <div className="enter-email-container">
                <div className="header">Forgot password?</div>
                <div className="sub-header">Kindly share your email address with us so we can help you reset your password</div>
                <div className="form">
                    <div className="form-header">Email</div>
                    <form noValidate autoComplete="off">
                        <TextField
                            name="email"
                            className="text-input"
                            label="Email"
                            variant="outlined"
                            value={email}
                            helperText={emailError}
                            onChange={(e) => this.handleText(e)}
                        />
                        <div className="form-helper-btns">
                            <span className="center">Do you remember your log in details? <span className="green"><Link to="/login">Log In</Link></span></span>
                        </div>
                        <Button onClick={() => this.submitNext()} title="Send" loading={loading} disabled={email.length === 0} />
                        <div className="MuiFormHelperText-root">
                            <span className="justify-content-center">{nextError}</span>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}