import React from 'react'
import { IRadioInputType } from 'constants/interfaces'

type RadioListProps={
    formlist:IRadioInputType
}

export default class RadioInput extends React.Component {
    state={
        selectedOption:0
    }
    props:{
        formlist:IRadioInputType,
        onOptionSelected: (any?: any) => any,
        name:string,
        value:number
    }
    constructor(props:any){
        super(props)
        this.props=props
    }
    handleOptionChange= (changeEvent: { target: { value: any } })=>{
        this.setState({
            selectedOption: changeEvent.target.value
        },()=>{ 
            const { onOptionSelected } = this.props
            if ( onOptionSelected) {
                onOptionSelected(this.state.selectedOption)
            }
        })
    }
    render() {
        const { formlist } = this.props
        const { selectedOption } = this.state
        return (
            <div className="radio-form-container">
                {
                formlist.list.map((line)=> 
                    <div className="form-check" key={line.id}>
                        <label>
                            <input
                                type="radio"
                                name={formlist.name}
                                value={line.id}
                                checked={line.id !== undefined && selectedOption.toString() === line.id.toString()}
                                onChange={this.handleOptionChange}
                                className="form-check-input"
                            />
                            {line.name ? line.name : line.type}
                        </label>
                    </div>
                )}
            </div>
        )
    }
}
