import { GraphQLClient } from 'graphql-request'
import { GRAPH_API } from 'constants/index'
import { IAuthResponseDTO, IClientUserProfile, IGenericResponseDTO, CategoryLine, IGenericType, IBidItem, IFilterDetails, Product } from './interfaces'
import { lshSAVEAUTH, lshSAVEEMAIL, lshGETAUTH } from './LocalStorageHelper'
import { raSET_USER } from '../appredux/actions';

function graphqlRequestHandler(name: any, query: any, variables: any, headers = {}): Promise<any> {
    return new Promise(
        (resolve, reject) => {
            let client = headers === null ? new GraphQLClient(GRAPH_API) : new GraphQLClient(GRAPH_API, { headers })
            client.request(query, variables)
                .then(data => {
                    resolve(name ? data[name] : data)
                })
                .catch(error => {
                    reject(error.response ? error.response.errors.map((i: any) => i.message) : error)
                })
        }
    )
};

export async function apiCLIENTSIGNUP(name: string, surname: string, email: string, phone: string): Promise<IGenericResponseDTO> {
    return new Promise(
        (resolve, reject) => {
            const query =
                ` 
                mutation variables($input: clientSignUpInput!){
                    clientSignUp(input:$input){
                        success
                        token
                        state
                        message
                      }
                  }`
            const variables = {
                "input": {
                    name,
                    surname,
                    email,
                    phone
                }
            }
            graphqlRequestHandler("clientSignUp", query, variables)
                .then(data => {
                    if (data.success) {
                        const auth = data as IAuthResponseDTO
                        lshSAVEAUTH(auth).then((d) => {
                            if (d.success) {
                                const user: IClientUserProfile = {
                                    name,
                                    surname,
                                    email,
                                    phone,
                                    clientRole: 1,
                                    active: false,
                                    verified: false
                                }
                                lshSAVEEMAIL(user.email).then((d) => {
                                    if (d.success) {
                                        resolve(data)
                                    } else {
                                        reject({ message: d.message })
                                    }
                                }).catch(error => {
                                    reject({ ...error })
                                })
                            } else {
                                reject({ message: d.message })
                            }
                        })
                    } else {
                        resolve({ success: data.success, message: data.message })
                    }
                })
                .catch(error => {
                    reject(error)
                })

        }
    )
};

export async function apiCLIENTVERIFYACCOUNT(code: string): Promise<IGenericResponseDTO> {
    return lshGETAUTH().then(data => {
        const headers = {
            Authorization: data
        }
        return new Promise(
            (resolve, reject) => {
                const query =
                    `
                mutation variables($code:String!){
                    clientVerifyAccount(code:$code){
                        success
                        message
                    }
                }`
                const variables = {
                    code
                }
                graphqlRequestHandler("clientVerifyAccount", query, variables, headers)
                    .then(data => {
                        if (data.success) {
                            resolve({ success: true, message: data.message })
                        } else {
                            resolve({ success: false, message: data.message })
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })

            }
        )
    })
};

export async function apiCREATECOMPANY(name: string,
    location: string,
    buyerTypeId: number,
    image: string = "https://khula.co.za/FarmerProfile/default.jpg"): Promise<IGenericResponseDTO> {
    return lshGETAUTH().then(data => {
        const headers = {
            Authorization: data
        }
        return new Promise(
            (resolve, reject) => {
                const query = `
                        mutation variables($input: companyInput!){
                            createCompany(input:$input){
                                success
                                message
                            }
                        }`
                const variables = {
                    "input": {
                        name,
                        location,
                        image,
                        buyerTypeId
                    }
                }
                graphqlRequestHandler("createCompany", query, variables, headers)
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
        )
    })

};

export async function apiUPDATECLIENTATTRIBUTE(input:{address?: string,
    name?: string,
    surname?: string,
    email?: string,
    phone?: string,
    image?: string,
    active?: number,
    password?: string}): Promise<IGenericResponseDTO> {

    return lshGETAUTH().then(data => {
        const headers = {
            Authorization: data
        }
        return new Promise(
            (resolve, reject) => {
                const query = `
                        mutation variables($input: clientInput){
                            updateClientAttribute(input:$input){
                                success
                                message
                            }
                        }`
                const variables = {
                    input
                }
                graphqlRequestHandler("updateClientAttribute", query, variables, headers)
                    .then(data => {
                        apiGETUSERPROFILE().then(d => {
                            resolve(data)
                        })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
        )
    })

};

export async function apiCLIENTLOGIN(username: string, password: string): Promise<IGenericResponseDTO> {
    return new Promise(
        (resolve, reject) => {
            const query = `
                    query variables($username:String!,$password:String!){
                        clientLogin(username:$username,password:$password){
                            token
                            success
                            message
                            state
                            verified
                        }
                    }`
            const variables = {
                username,
                password
            }
            graphqlRequestHandler("clientLogin", query, variables)
                .then(data => {
                    const auth = data as IAuthResponseDTO
                    if(auth.success){
                        lshSAVEAUTH(auth).then((d) => {
                            if (d.success) {
                                apiGETUSERPROFILE().then(d => {
                                    resolve(d)
                                })
                            } else {
                                reject({ message: d.message })
                            }
                        })
                    } else {
                        reject({ message: auth.message })
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )

};

export async function apiGETUSERPROFILE(): Promise<IGenericResponseDTO> {
    return lshGETAUTH().then(data => {
        const headers = {
            Authorization: data
        }
        return new Promise(
            (resolve, reject) => {
                const query = `
                query {
                    getMyClientDetails{
                        name
                        surname
                        email
                        phone
                        location
                        picture
                        active
                        clientRole
                        latlong
                        verified
                        company{
                            id
                            name
                        }
                    }
                }`
                const variables = {}
                graphqlRequestHandler("getMyClientDetails", query, variables, headers)
                    .then(data => {
                        const user = data as IClientUserProfile
                        if (user) {
                            raSET_USER(user)
                            resolve({success:true})
                        } else {
                            reject({ message: "no user data" })
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
        )
    })
};

export async function apiGETALLPRODUCEPERCATEGORY(_productLimit:number,_allProduce?:boolean): Promise<Array<CategoryLine>> {
    return new Promise(
        (resolve, reject) => {
            const query =
                `
            query variables($productLimit: Int, $allProduce:Boolean){
                getAllProducePerCategories(productLimit:$productLimit,allProduce:$allProduce){
                  id
                  name
                  products{
                    id
                    category
                    name
                    lowerPrice
                    upperPrice
                    quantity
                    packaging{
                      id
                      packaging
                    }
                  }
                }
              }
            `
            const variables = {
                productLimit:_productLimit,
                allProduce:_allProduce
            }
            graphqlRequestHandler("getAllProducePerCategories", query, variables)
                .then(data => {
                    const dataCheck = data as Array<any>
                    if (dataCheck) {
                        resolve(dataCheck)
                    } else {
                        reject({message: "no data"})
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )



}

export async function apiGETPRODUCECATEGORIES(): Promise<Array<IGenericType>> {
    return new Promise(
        (resolve, reject) => {
            const query =
                ` 
                query {
                    getProduceCategories{
                      id
                      name
                    }
                  }`
            const variables = {}
            graphqlRequestHandler("getProduceCategories", query, variables)
                .then(data => {
                    const dataCheck = data as Array<IGenericType>
                    if (dataCheck) {
                        resolve(dataCheck)
                    } else {
                        reject({ message: "no data" })
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )
};

export async function apiCLIENTFORGOTPASSWORD(username:string): Promise<IAuthResponseDTO> {
    return new Promise(
        (resolve, reject) => {
            const query = `
            query variables($username:String!){
                clientForgotPassword(username:$username) {
                    token
                    success
                    message
                }
            }`
            const variables = { username }
            graphqlRequestHandler("clientForgotPassword", query, variables)
                .then(data => {
                    if (data.success) {
                        const auth = data as IAuthResponseDTO
                        lshSAVEAUTH(auth).then((d) => {
                            if (d.success) {
                                resolve(data)
                            } else {
                                reject({ message: d.message })
                            }
                        })
                    } else {
                        reject({ message: data.message })
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )
};

export async function apiCLIENTGETBIDS(): Promise<Array<IBidItem>> {
    return lshGETAUTH().then(data => {
        const headers = {
            Authorization: data
        }
        return new Promise(
            (resolve, reject) => {
                const query = `
            query {
                  clientGetBids{
                    id
                    quantity
                    quantityLeft
                    price
                    description
                    dueDate
                    orderId
                    grading
                    insertedOn
                    clientId
                    clientPrice
                    packaging
                    produce{
                      id
                      name
                      category
                    }
                    responseYes
                    responseMaybe
                  }
            }`

                const variables = {}
                graphqlRequestHandler("clientGetBids", query, variables, headers)
                    .then(info => {
                        const data = info.map((item: { productName: any; produce: { name: any; }; }) => {
                            item.productName = item.produce.name
                            return item
                        })

                        const dataCheck = data as Array<IBidItem>
                        if (dataCheck) {
                            resolve(dataCheck)
                        } else {
                            reject({ message: "no data" })
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
        )
    })
};

export async function apiGETAVAILABLEPRODUCE(filters?: {search?:string,category?:number,growthType?:number,grading?:number,packaging?:number,pickoftheweek?:boolean}): Promise<Array<Product>> {
    return new Promise(
        (resolve, reject) => {
            const query =
                ` query variables($input:ProduceFiltersInput){
                    getAvailableProduce(filter: $input){
                        id
                        name
                        pickoftheweek
                        category
                        quantity
                        lowerPrice
                        upperPrice
                        farmers
                        packaging{
                            id
                            packaging
                        }
                    }
                  }`
            const variables = filters ? { input: filters } : {}
            graphqlRequestHandler("getAvailableProduce", query, variables)
                .then(data => {
                    const dataCheck = data as Array<Product>
                    if (dataCheck) {
                        resolve(dataCheck)
                    } else {
                        reject({ message: "no data" })
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )
};

export async function apiGETPRODUCEFILTERS(): Promise<IFilterDetails> {
    return new Promise(
        (resolve, reject) => {
            const query =
                ` query {
                    getProduceFilters{
                      grading{
                        id
                        type
                      }
                      packaging{
                        id
                        name
                      }
                      category{
                        id
                        name
                      }
                      growthType{
                        id
                        type
                      }
                      certificationType{
                        id
                        type
                      }
                    }
                  }`
            const variables = {}
            graphqlRequestHandler("getProduceFilters", query, variables)
                .then(data => {
                    const dataCheck = data as IFilterDetails
                    if (dataCheck) {
                        dataCheck.province=[
                            {
                                name:"Gauteng"
                            },
                            {
                                name:"Mpumalanga"
                            },
                            {
                                name:"KwaZulu Natal"
                            },
                            {
                                name:"Western Cape"
                            },
                            {
                                name:"Limpopo"
                            },
                            {
                                name:"Freestate"
                            },
                            {
                                name:"North West"
                            },
                            {
                                name:"Eastern Cape"
                            },
                            {
                                name:"Northern Cape"
                            }
                        ]
                        resolve(dataCheck)

                    } else {
                        reject({ success: false, message: "no data" })
                    }
                })
                .catch(error => {
                    reject(error)
                })
        }
    )
};
