import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo_white.png'
import SignupStepper from '../SignUp/Component/SignupStepper'
import EnterEmail from './Components/EnterEmail'
import ConfirmCode from './Components/ConfirmCode'
import ResetPassword from './Components/ResetPassword'

export default class ForgotPassword extends React.Component {
    state = {
        currentStep: 1,
        email:"",
        stepTitles: [
            "Provide Email",
            "Confirmation Code",
            "Reset Password"
        ]

    }
    doNext(currentStep: number) {
        this.setState({ currentStep: currentStep + 1 })
    }
    render() {
        const { currentStep, stepTitles } = this.state
        return (
            <div className="signup-container roboto container">
                <div className="row">
                    <div className="right-content">

                        <div className="text-container">
                            <div className="r-sub-header">Already have an account?</div>
                            <div className="r-content">Log in to your account so you explore the Market and buy good food anywhere.</div>
                            <div className="sign-up-btn"><Link to="/login">Log In</Link></div>
                        </div>

                    </div>
                    <div className="left-content">
                        <span className="icon webicon-close close-ic" />
                        <div className="logo">
                            <img className="logo-img" src={logo} alt="logo" />
                        </div>
                        <div className="l-content-parent">
                            <div className="progress-container">
                                <SignupStepper step={currentStep} totalSteps={3} stepTitle={stepTitles[currentStep - 1]} />
                            </div>
                            <div className="l-content-container form-container">
                                {currentStep === 1 && <EnterEmail onNext={(variable) => {this.setState({email:variable});this.doNext(1)}} />}
                                {currentStep === 2 && <ConfirmCode email={this.state.email} onNext={(variable) => this.doNext(2)} />}
                                {currentStep === 3 && <ResetPassword />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
