import React from 'react'

export default class SignupStepper extends React.Component {
    props: {
        step: number
        totalSteps: number
        stepTitle: string
    }
    static defaultProps = {
        step: 1,
        totalSteps: 0
    }
    constructor(props: any) {
        super(props)
        this.props = props
    }
    render() {
        const { step, totalSteps, stepTitle } = this.props
        return (
            <div className="progress-bar row">
                {
                    (new Array(totalSteps)).fill(0.0, 0, totalSteps).map((data, index) =>
                        [
                            <div className={`stepper ${index === (step - 1) ? "active" : ""}`}>
                                <div className={`stepper-bg-container ${index === (step - 1) ? "active" : ""}`}>
                                    <div className={`stepper-bg ${index === (step - 1) ? "active" : index < step ? "passed" : ""}`}>
                                        {index === (step - 1) && <span className="stepper-number">{index + 1}</span>}
                                    </div>
                                </div>
                                {index === (step - 1) && <p className="stepper-txt">{stepTitle}</p>}
                            </div>,
                            index !== (totalSteps - 1) && <span className={`line ${index < (step-1) ? "passed" : ""}`}></span>
                        ]
                    )
                }
            </div>
        )
    }
}