import React from 'react'
import { Link } from 'react-router-dom'
import CompanyDetails from 'screens/SignUp/Component/CompanyDetails'
import PersonalDetailsSignup from 'screens/SignUp/Component/PersonalDetailsSignup'
import logo from 'assets/images/logo_white.png'
import logo_green from 'assets/images/logo.png'
import ConfirmCode from './Component/ConfirmCode'
import CreatePassword from './Component/CreatePassword'
import SignupStepper from './Component/SignupStepper'
import { RouteScreenProps } from 'constants/interfaces'

export default class SignUp extends React.Component<RouteScreenProps> {
    state = {
        currentStep: 1,
        email:"",
        stepTitles:[
            "Personal Details",
            "Confirmation Code",
            "Company Details",
            "Create Password"
        ]

    }
    doNext(currentStep: number) {
        this.setState({ currentStep: currentStep + 1 })
    }
    render() {
        const { currentStep, stepTitles } = this.state
        return (
            <div className="signup-container roboto container">
                <div className="row">
                    <div className="right-content">
                        
                        <div className="text-container">
                            <div className="r-sub-header">Already have an account?</div>
                            <div className="r-content">Log in to your account so you explore the Market and buy good food anywhere.</div>
                            <Link to="/login"><div className="sign-up-btn">Log In</div></Link>
                        </div>

                    </div>
                    <div className="left-content">
                        <span className="icon webicon-close close-ic"/>
                        <div className="logo">
                            <img className="logo-img" src={window.innerWidth < 600 ? logo_green : logo} alt="logo" />
                        </div>
                        <div className="l-content-parent">
                            <div className="progress-container">
                                <SignupStepper step={currentStep} totalSteps={4} stepTitle={stepTitles[currentStep-1]} />
                            </div>
                            <div className="l-content-container form-container">
                                {currentStep === 1 && <PersonalDetailsSignup onNext={(variable) => {this.setState({email:variable});this.doNext(1)}} />}
                                {currentStep === 2 && <ConfirmCode email={this.state.email} onNext={(variable) => this.doNext(2)} />}
                                {currentStep === 3 && <CompanyDetails onNext={(variable) => this.doNext(3)} />}
                                {currentStep === 4 && <CreatePassword history={this.props.history}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
