import React from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import logo from 'assets/images/logo.png'
import { apiCLIENTLOGIN } from 'constants/Api'
import Button from 'components/Button/Button'
import { RouteScreenProps } from 'constants/interfaces';

export default class Login extends React.Component<RouteScreenProps> {
    state = {
        username: "",
        password: "",
        usernameError: "",
        passwordError: "",
        nextError: "",
        loading: false
    }
    validate = () => {
        const completeMessage = "Please complete this field";
        let usernameError = (this.state.username.trim() === "") ? completeMessage : null,
            passwordError = (this.state.password.trim() === "") ? completeMessage : null

        this.setState({
            usernameError,
            passwordError
        })
        return !usernameError && !passwordError
    }
    handleText(e: any) {
        const input = e.target
        this.setState({
            nextError: null,
            [input.name]: input.value,
            [`${input.name}Error`]: null
        })
    }
    submitNext() {
        if (this.validate()) {
            const { username, password } = this.state
            this.setState({ loading: true }, () => {

                apiCLIENTLOGIN(username, password).then((response) => {
                    if (response.success) {
                        this.props.history.push("/")
                        this.setState({ loading: false })
                    } else {
                        this.setState({ nextError: response.message, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ nextError: e.message, loading: false })
                })

            })
        }
    }
    render() {
        const { username, password, usernameError, passwordError, nextError, loading } = this.state

        return (

            <div className="login-container roboto container">
                <div className="row">
                    <div className="left-content">
                        <div className="logo">
                            <img className="logo-img" src={logo} alt="logo" />
                        </div>

                        <div className="l-content-container">
                            <div className="header">Log in to your <span className="green">Khula!</span> Account</div>
                            <div className="sub-header"> Log in to your account so you explore the Market and buy good food anywhere</div>
                            <div className="form">
                                <form noValidate autoComplete="off">
                                    <TextField
                                        name="username"
                                        className="text-input"
                                        label="Email/Username"
                                        variant="outlined"
                                        value={username}
                                        helperText={usernameError}
                                        onChange={(e) => this.handleText(e)}
                                    /><br />
                                    <TextField
                                        name="password"
                                        className="text-input"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={password}
                                        helperText={passwordError}
                                        onChange={(e) => this.handleText(e)}
                                    />
                                    <div className="form-helper-btns">
                                        <FormControlLabel
                                            value="Remember Me"
                                            control={<Checkbox color="primary" />}
                                            label="Remember Me"
                                            labelPlacement="end"
                                        />
                                        <Link to="/forgot-password"><div className="forgot-pswd green">Forgot Password?</div></Link>
                                    </div>
                                    <Button onClick={() => this.submitNext()} title="Log In" loading={loading} disabled={username.length === 0 || password.length === 0} />
                                    <br />
                                    <div className="MuiFormHelperText-root">
                                        <span className="justify-content-center">{nextError}</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="text-container">
                            <div className="r-sub-header">Don't have an account yet?</div>
                            <div className="r-content">Let's get you all set up so you can start placing your first orders</div>
                            <Link to="/sign-up"><div className="sign-up-btn">Sign Up</div></Link>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
