import React from 'react'
import { IFilterDetails } from 'constants/interfaces'
import { apiGETPRODUCEFILTERS } from 'constants/Api'
import FilterCategory from './Component/FilterCategories'

type FiltersProps = {
    onApplyFilters: (filters: any) => any
}

export default class Filters extends React.Component<FiltersProps> {
    state: {
        filters: IFilterDetails,
        selectedFilters: IFilterDetails,
        canApply: boolean
    } = {
            filters: {},
            selectedFilters: {},
            canApply: false
        }

    componentDidMount() {
        apiGETPRODUCEFILTERS().then((filters) => {
            const _filters = filters
            this.setState({ filters: _filters })
        }).catch((e) => {
        })
    }
    onSelect(filterDetail: string, array: any) {
        const name = filterDetail as "grading" | "packaging" | "category" | "growthType" | "certificationType" | "province"
        const { selectedFilters } = this.state
        selectedFilters[name]=array;
        this.setState({selectedFilters,canApply:true})
    }
    onApply() {
        const { category, growthType, grading, packaging, certificationType, province } = this.state.selectedFilters
        const { onApplyFilters } = this.props
        let filters: any = {}
        if (category && category.length > 0) {
            filters.category = category.map((item) => item.id)
        }
        if (growthType && growthType.length > 0) {
            filters.growthType = growthType.map((item) => item.id)
        }
        if (certificationType && certificationType.length > 0) {
            filters.certificationType = certificationType.map((item) => item.id)
        }
        if (grading && grading.length > 0) {
            filters.grading = grading.map((item) => item.id)
        }
        if (packaging && packaging.length > 0) {
            filters.packaging = packaging.map((item) => item.name)
        }
        if (province && province.length > 0) {
            filters.province = province.map((item) => item.name)
        }

        if (onApplyFilters) {
            onApplyFilters(filters)
        }
    }
    render() {
        const { filters, canApply } = this.state
        return (
            <div className="filters-container row" >
                {
                    Object.keys(filters).map((filterDetail: string, index: number) => {
                        const name = filterDetail as "grading" | "packaging" | "category" | "growthType" | "certificationType" | "province"
                        return (
                            <div key={index}>
                                <FilterCategory onSelect={(name, array) => this.onSelect(name, array)} items={filters[name]} name={name} />
                            </div>
                        )
                    })
                }
                <div className="filter-btn pointer" onClick={() => this.onApply()} style={canApply ? { backgroundColor: "#387C3F" } : { backgroundColor: "#C7C7C7" }}>
                    <div >Apply</div>
                </div>
            </div>
        )
    }
}
