import React from "react"
import SideBarItem from './Component/SideBarItem';
import { raSET_USER } from "../../appredux/actions";
import { RouteScreenProps } from "constants/interfaces";
import { lshREMOVEAUTH } from "constants/LocalStorageHelper";

export default class Sidebar extends React.Component<RouteScreenProps> {
    state: {
        sidebarOpen: boolean
    } = {
        sidebarOpen: false
    }
    logout(){
        raSET_USER(undefined)
        lshREMOVEAUTH()
        this.props.history.push("/login")
    }
    render() {
        return (
            <div className={`navmenu-container ${this.state.sidebarOpen ? "open" : ""}`} onMouseEnter={() => { this.setState({ sidebarOpen: true }) }} onMouseLeave={() => { this.setState({ sidebarOpen: false }) }}>
                <div className="top-btns">
                    <SideBarItem to="/" icon="home-ic" title="Home"/>
                    <SideBarItem to="market" icon="market-ic" title="Market"/>
                    <SideBarItem to="bids" icon="clock" title="Bids"/>
                    <SideBarItem to="orders" icon="truck1" title="Orders"/>
                    <SideBarItem to="profile" icon="profile-ic" title="Profile"/>
                    <SideBarItem to="settings" icon="settings-ic" title="Settings"/>
                </div>
                <div className="bottom-btns">
                    <div className="logout-btn btn" onClick={()=>this.logout()}>
                        <div className="btn-icon-container">
                            <span className="icon icon-logout-ic"></span>
                        </div>
                        <span className="nav-title">logout</span>
                    </div>
                </div>
            </div>
        );
    }
}
