import React from 'react'
import { IFilterItem } from 'constants/interfaces'
import { FormControlLabel, Checkbox } from '@material-ui/core'

type FilterCategoriesProps = {
    items?: Array<IFilterItem>,
    name: string,
    onSelect: (name: string,array:Array<any>) => any
}

export default class FilterCategories extends React.Component<FilterCategoriesProps>{
    state: {
        items: Array<any>,
        showItems: boolean,
        selectedItem: Array<any>
    } = {
            items: this.props.items || [],
            showItems: false,
            selectedItem: [],
        }

    selectItem(item: any) {
        const { selectedItem } = this.state
        let check = selectedItem.find(it => item.id ? item.id === it.id : item.type ? item.type === it.type : item.name === it.name)
        const index = selectedItem.indexOf(check)
        index === -1 ? selectedItem.push(item) : selectedItem.splice(index, 1)
        this.setState({ selectedItem })
        this.props.onSelect(this.props.name,selectedItem)
    }

    dropDown = () => {
        const { showItems } = this.state
        this.setState({ showItems: !showItems })
    }

    render() {
        const { name } = this.props
        const { items, selectedItem, showItems } = this.state

        return (
            <div className="select-box-box pointer" onClick={() => this.dropDown()}>
                <div className="select-box-container">
                    <div className="select-box-selected-item">
                        {selectedItem.length === 1 ? (selectedItem[0]?.name || selectedItem[0]?.type) : `${name[0].toLocaleUpperCase() + name.slice(1)}${selectedItem.length > 1 ? ` (${selectedItem.length})` : ""}`}
                    </div>
                    <div className="select-box-arrow" >
                        <span className={`icon-chevron-${showItems ? 'up' : 'down'}`}></span>
                    </div>
                    {
                        <div style={{ display: showItems ? 'block' : 'none' }}
                            className="select-box-items pointer"
                            onMouseLeave={() => this.dropDown()}>
                            {
                                items.map((item, index) =>
                                    <div key={index}>
                                        <FormControlLabel
                                            name={item.name || item.type}
                                            value={item.name || item.type}
                                            control={<Checkbox color="primary" />}
                                            label={item.name || item.type}
                                            labelPlacement="end"
                                            onChange={() => this.selectItem(item)}
                                        />

                                    </div>)
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
