import React from 'react';
import { connect } from "react-redux";
import { Switch, Route, useHistory } from 'react-router-dom'
import ForgotPassword from 'screens/ForgotPassword/ForgotPassword'
import Login from 'screens/Login/Login'
import SignUp from 'screens/SignUp/SignUp'
import Dashboard from 'screens/Dashboard/Dashboard'
import 'App.css'
import 'assets/styles/index.css'
import 'assets/styles/style.css'
import { IClientUserProfile } from 'constants/interfaces'
import { apiGETUSERPROFILE } from 'constants/Api'
import { lshGETAUTH } from 'constants/LocalStorageHelper'
import Loader from 'components/Loader/Loader'

type AppProps = {
  user: IClientUserProfile
  history: any
  location: any
}

class App extends React.Component<AppProps> {
  state: {
    loading: boolean
  } = {
      loading: false
    }
  componentWillMount() {
    const { user, history } = this.props
    if (JSON.stringify(user) === "{}") {
      this.setState({ loading: true }, () => {
        lshGETAUTH().then(token => {
          if (token) {
            apiGETUSERPROFILE().then(() => {
              this.setState({ loading: false })
              history.push("/")
            })
          } else {
            this.setState({ loading: false })
            history.push("/login")
          }
        })
      })
    }
  }
  render() {
    const { loading } = this.state
    return (
      loading ?
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh", flexDirection: "column" }}>
          <Loader size={80} />
          <div style={{ marginTop: "1rem" }}>Loading</div>
        </div> :
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/" component={Dashboard} />
        </Switch>
    )
  }
}

function mapStateToProps(state: any) {
  return ({
    user: state.user,
  });
}

const MainApp = (...props: any) => {
  let history = useHistory()
  return (
    <App history={history} {...props[0]} />
  )
}

export default connect(mapStateToProps)(MainApp)
