import React from 'react'
import { FeaturedProduct } from 'constants/interfaces'
import BiddingCard from 'components/BiddingCard/BiddingCard'

type FeatureCardProps={
    title:string,
    products:Array<FeaturedProduct>,
    errorMsg: string
}
export default class FeatureCard extends React.Component<FeatureCardProps> {
    render() {
        const {title,products,errorMsg} = this.props
        return (
        <div className="feature-card-container">
            <div className="feature-card-header">{title}</div>
            <hr/>
            <div className="cards-container row">
                {
                    products && products.map((mapProduct,index) => <BiddingCard product={mapProduct} key={index}/>)
                }
            </div>
            <p style={{color:"grey"}}>{errorMsg}</p>
         </div>
        )
    }
}
