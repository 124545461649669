import React from 'react'
import { apiGETAVAILABLEPRODUCE } from 'constants/Api'
import { Product } from 'constants/interfaces'
import Slider from 'react-slick'
import ProductCard from 'components/ProductCard/ProductCard'


const width = window.innerWidth
const numberOfVisible = width < 1200 ? width < 1100 ? width < 1000 ? width < 768 ? width < 500 ? 1 : 2 : 3 : 4 : 5 : 6


export default class FrequentlyOrdered extends React.Component {
    state: {
        frequentlyOrdered: Array<Product>

    } = {
            frequentlyOrdered: []
        }

    componentDidMount() {
        apiGETAVAILABLEPRODUCE({pickoftheweek:true}).then((frequentlyOrdered) => {
            this.setState({ frequentlyOrdered: frequentlyOrdered })
            console.log(this.state.frequentlyOrdered)
        })
    }
    render() {
        const {frequentlyOrdered } = this.state
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToScroll: 1,
        };

        return (
            <div className="client-container">
                <div className="feature-card-header">Frequently ordered</div>
                <hr/>
                {
                    <div className="category-right-content">
                        <div className="category-products">
                            <Slider {...settings} {...{ slidesToShow: frequentlyOrdered.length > numberOfVisible ? numberOfVisible : frequentlyOrdered.length }}>
                                {
                                    frequentlyOrdered.map((mapProduct, index) =>
                                        <div className="test" key={index}>
                                            {
                                                mapProduct ?
                                                    <ProductCard product={mapProduct} onRequest={(requestProduct) => this.setState({ requestModalVisible: true, requestProduct })} /> :
                                                    <div className="product-card-faux"></div>
                                            }
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
