import React from 'react'
import Header from 'components/Header/Header'
import SideBar from 'components/SideBar/SideBar'
import HomeScreen from './Home/HomeScreen'
import { Switch, Route } from 'react-router-dom'
import { RouteScreenProps } from 'constants/interfaces'
import Market from './Market/Market'


export default class Dashboard extends React.Component<RouteScreenProps> {
    render() {
        return (

            <div className="dashboard-container roboto">
                <div className="header-section">
                    <Header />
                </div>
                <div className="sidebar-content-section">
                    <div className="sidebar-section">
                        <SideBar history={this.props.history} />
                    </div>
                    <div className="content-section">
                        <Switch>
                            <Route path="/" exact>
                                <HomeScreen />
                            </Route>
                            <Route path="/market" exact>
                                <Market/>
                            </Route>
                        </Switch>
                    </div>
                </div>

            </div>
        )
    }
}
