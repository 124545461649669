import React from 'react'
import ClientMarket from './Component/ClientMarket/ClientMarket'
import ClientProfile from './Component/ClientProfile/ClientProfile'
import FeatureCard from './Component/FeaturedCard/FeaturedCard'
import { FeaturedProduct } from 'constants/interfaces'
import { apiCLIENTGETBIDS, apiGETAVAILABLEPRODUCE } from 'constants/Api'

export default class HomeScreen extends React.Component {
    state: {
        bids: Array<FeaturedProduct>,
        frequentlyOrdered: Array<FeaturedProduct>,
        bidsErrorMsg: string,
        ordersErrorMsg: string
    } = {
            bids: [],
            frequentlyOrdered: [],
            bidsErrorMsg: "",
            ordersErrorMsg: ""
        }
    componentDidMount() {
        apiCLIENTGETBIDS().then((bids) => {
            bids = bids.splice(0, 5)
            this.setState({ bids })
            if (bids.length === 0) {
                this.setState({ bidsErrorMsg: "You currently have no recent bids" })
            }
        }).catch((e) => {
            this.setState({ bidsErrorMsg: e.message })
        })

        apiGETAVAILABLEPRODUCE().then((frequentlyOrdered) => {
            frequentlyOrdered = frequentlyOrdered.splice(0, 5)
            this.setState({ frequentlyOrdered: frequentlyOrdered })
        })
    }
    render() {
        const { bids, frequentlyOrdered, bidsErrorMsg, ordersErrorMsg } = this.state
        return (
            <div>
                <ClientProfile />
                <div className="featured-cards row">
                    <FeatureCard title="Recent Bids" products={bids} errorMsg={bidsErrorMsg} />
                    <FeatureCard title="Frequently Ordered" products={frequentlyOrdered} errorMsg={ordersErrorMsg} />
                </div>
                <ClientMarket />
            </div>
        )
    }
}
