import React from 'react'
const ReactCodeInput = require("../../../node_modules/react-verification-code-input/dist/index")

type CodeInputProps={
    onChange?:(any?:any)=>any,
    onComplete?:(any?:any)=>any,
}
export default class CodeInput extends React.Component<CodeInputProps> {
    render() {
        const {onChange,onComplete}=this.props
        return (
            <ReactCodeInput onChange={(value:string)=>onChange?onChange(value):null} onComplete={(value:any)=>onComplete?onComplete(value):null} fields={5} className="code-input" />
        )
    }
}
