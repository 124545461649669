import React from 'react';
import { NavLink } from 'react-router-dom';
type SideBarItemType={
    to:string,
    title:string,
    icon:string
}
class SideBarItem extends React.Component<SideBarItemType> {
    render() {
        const {to,title,icon}=this.props
        return (
            <NavLink to={to} className="btn" exact activeClassName="active">
                <div className="btn-icon-container">
                    <span className={`icon icon-${icon}`}></span>
                </div>
                <span className="nav-title">{title}</span>
            </NavLink>
        )
    }
}

export default SideBarItem