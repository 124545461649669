import React from 'react'
import Filters from 'components/Filters/Filters'
import FrequentlyOrdered from './Component/FrequentlyOrdered'
import { apiGETAVAILABLEPRODUCE } from 'constants/Api'
import ProductCard from 'components/ProductCard/ProductCard'

export default class Market extends React.Component {
    state = {
        products: []
    }
    componentDidMount() {
        this.getProduce()
    }
    getProduce(filters?: any) {
        apiGETAVAILABLEPRODUCE(filters).then((products) => {
            this.setState({ products }, () => {})
        })
    }
    render() {
        const { products } = this.state
        return (
            <div className="market-container roboto">
                <Filters onApplyFilters={(filters) => this.getProduce(filters)} />
                <div className="featured">
                    <FrequentlyOrdered />
                </div>
                <div className="products block">
                    {
                        products && products.map((mapProduct, index) => <ProductCard key={index} product={mapProduct} />)
                    }
                </div>
            </div>
        )
    }
}
