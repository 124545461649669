import React from 'react'
import { CategoryLine } from 'constants/interfaces'
import ProductCard from 'components/ProductCard/ProductCard'
import Slider from 'react-slick'
import { apiGETALLPRODUCEPERCATEGORY } from 'constants/Api'

const width = window.innerWidth
const numberOfVisible = width < 1200 ? width < 1100 ? width < 1000 ? width < 768 ? width < 500 ? 1 : 2 : 3 : 4 : 5 : 6
const BackgroundImages = (id: number | string) => {
    const check = typeof id === "string" ? parseInt(id) : id
    switch (check) {
        case 1: return require("assets/images/fruit-cat.JPG")
        case 2: return require("assets/images/veg-cat.PNG")
        case 3: return require("assets/images/herb-cat.PNG")
        case 4: return require("assets/images/nut-cat.JPG")
        default:
            break;
    }
}

const BackgroundGradient = (id: number | string) => {
    const check = typeof id === "string" ? parseInt(id) : id
    switch (check) {
        case 1: return "linear-gradient(270deg, white 0%, #BD6F1C40 50%)"
        case 2: return "linear-gradient(270deg, white 0%, #FF2C3E40 50%)"
        case 3: return "linear-gradient(270deg, white 0%, #089C0340 50%)"
        case 4: return "linear-gradient(270deg, white 0%, #95521E40 50%)"
        default:
            break;
    }
}

export default class Client extends React.Component {
    state: {
        categories: Array<CategoryLine>
    } = {
            categories: []
        }

    componentDidMount() {
        apiGETALLPRODUCEPERCATEGORY(10,true).then((categories) => {
            categories = categories.filter((category) => category.products.length > 0).map(category=>{
                const len=category.products.length
                if(len<=numberOfVisible){
                    for (let index = 0; index < numberOfVisible-len+1; index++) {
                        category.products.push(undefined)
                    }
                }
                return category
            })
            this.setState({ categories, loading: false })
        })
    }

    render() {
        const { categories } = this.state
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToScroll: 1,
        };

        return (
            <div className="client-container">
                {
                    categories.length > 0 && categories.map((category, index) => {
                        return (
                            <div className="category-container" key={index}>
                                <div className="bg-image-container container-child">
                                    <div className="bg-gradient-container">
                                        <div className="bg-gradient bg-gradient-container-child" style={{ background: BackgroundGradient(category.id) }}></div>
                                        <img className="bg-img bg-gradient-container-child" src={BackgroundImages(category.id)} alt="category-background" />
                                    </div>
                                </div>
                                <div className="bg-gradient-overlay container-child" style={{ background: BackgroundGradient(category.id) }}>

                                </div>
                                <div className="content-container container-child">
                                    <div className="category-left-content">
                                        <div className="category-title">{category.name}</div>
                                        <div className="category-btn">See More</div>
                                    </div>

                                    <div className="category-right-content">
                                        <div className="category-products">
                                            <Slider {...settings} {...{ slidesToShow: category.products.length > numberOfVisible ? numberOfVisible : category.products.length }}>
                                                {
                                                    category.products.map((mapProduct, index) =>
                                                        <div className="test" key={index}>
                                                        {
                                                            mapProduct ?
                                                                <ProductCard product={mapProduct} onRequest={(requestProduct)=>this.setState({requestModalVisible:true,requestProduct})}/> : 
                                                                <div className="product-card-faux"></div>
                                                        }
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }

            </div>
        )
    }
}