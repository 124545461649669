import React from 'react'
import Logo from 'assets/images/Logo.svg'
import Select from 'react-dropdown-select'
import { apiGETPRODUCECATEGORIES } from 'constants/Api'
import { connect } from 'react-redux';
import { IClientUserProfile } from 'constants/interfaces';

type HeaderProps={
  user:IClientUserProfile
}
class Header extends React.Component<HeaderProps> {
    state: {
        options:Array<{value:number,label:string}>
        filterItem?: {value:number,label:string}
        displayPic:string
    } = {
        options:[],
        filterItem: {value:0,label:"All Categories"},
        displayPic:""
    }
    componentDidMount() {
        apiGETPRODUCECATEGORIES().then((options) => {
            const filterOptions=options.map(option=>{ return {value:option.id,label:option.name}})
            filterOptions.unshift({value:0,label:"All Categories"})
            this.setState({ options:filterOptions })
        })
    }

    render () {
       const { filterItem , options} = this.state
      return (
        <div className="header-container">
          <div className="header-contents">
            <div className="header-left">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="search">
                <Select className="search-categories"
                  placeholder="All Categories"
                  options={options}
                  values={[filterItem]}
                  onChange={(item: any) => this.setState({ filterItem: item[0].value })}
                />
                <div className="search-content">
                  <input className="search-text" type="text" placeholder="What are you looking for?"></input>
                  <span className="icon webicon-search search-ic"></span>
                </div>
              </div>
            </div>
            <div className="links">
              <div className="links-ic">
                <span className="icon webicon-lnr-cart cart-ic"></span>
                <span className="icon webicon-lnr-clock truck-ic"></span>
              </div>
              <div>
                  <img className="profile-ic" src={this.props.user.picture} alt="user-profile"/>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps(state: any) {
  return ({
    user: state.user,
  });
}
export default connect(mapStateToProps)(Header)