import React from 'react'
import { Product } from 'constants/interfaces'
import placeholder from 'assets/images/img_placeholder.svg'
import { _GetImagePath } from 'constants/index'
type ProductCardProps = {
    product: Product
    onRequest?: (product: Product) => any
    onFarmers?: (product: Product) => any
    onBid?: (product: Product) => any
}
export default class ProductCard extends React.Component<ProductCardProps>{
    state: {
        src: string
    } = {
            src: ""
        }
    componentDidMount() {
        const { product } = this.props
        this.setState({ src: _GetImagePath(product.name) })
    }
    onError() {
        this.setState({ src: `${placeholder}` })
    }
    render() {
        const { product } = this.props
        const { src } = this.state
        const noFarmer=product.packaging===null
        const upperPrice=product.upperPrice.toFixed(2)
        let lowerPrice=product.lowerPrice.toFixed(2)
        lowerPrice=upperPrice===lowerPrice?(0).toFixed(2):lowerPrice;
        return (
            <div className="product-card col-xl col-l col-m col-sm col-xs">
                <div className="product-name">{product.name}</div>
                <div className="product-details">
                    <div className="product-img-container">
                        <img className="product-img" alt={product.name} onError={(e)=>this.onError()}  src={src} />
                    </div>
                    {!noFarmer && <div className="product-price">R{lowerPrice} - R{upperPrice}</div>}
                    <div className="product-qty">QTY: {product.quantity} {noFarmer?"":product.packaging[0].packaging}</div>
                </div>
                <div className="product-btns">
                    {
                        noFarmer ?
                            <div className="product-btn request-btn" onClick={() => alert("Request:"+product.name)} >Request</div> :
                            [
                                <div key={1} className="bid-btn product-btn" onClick={(e) => alert("Bid:"+product.name)} >Bid</div>,
                                <div key={2} className="farmers-btn product-btn" onClick={(e) => alert("Farmers:"+product.name)}>Farmers</div>
                            ]
                    }
                </div>
            </div>
        )
    }
}